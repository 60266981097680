import React, { CSSProperties, useMemo } from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { EnvironmentState } from "../../../services/registry/EnvironmentService";
import { LedIndicator, LedIndicatorColor } from "../../../components/LedIndicator";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export interface EnvironmentStateIndicatorProps {
  state: EnvironmentState;
  style?: CSSProperties;
}

export const EnvironmentStateIndicator = ({ state, style }: EnvironmentStateIndicatorProps) => {
  const [label, color] = useMemo(() => {
    switch (state) {
      case EnvironmentState.REFRESHING:
        return ["Refreshing...", LedIndicatorColor.BLUE];
      case EnvironmentState.DEPLOYING:
        return ["Deploying...", LedIndicatorColor.BLUE];
      case EnvironmentState.HEALTHY:
        return ["Healthy", LedIndicatorColor.GREEN];
      case EnvironmentState.UNHEALTHY:
        return ["Unhealthy", LedIndicatorColor.RED];
      case EnvironmentState.INITIALIZATION_FAILED:
        return ["Initialization failed", LedIndicatorColor.RED];
      case EnvironmentState.TERMINATION_FAILED:
        return ["Termination failed", LedIndicatorColor.RED];
      case EnvironmentState.RUNNING:
      case EnvironmentState.INITIALIZED:
        return ["Backend not ready", LedIndicatorColor.YELLOW];
      case EnvironmentState.PENDING_INITIALIZATION:
        return ["Initializing...", LedIndicatorColor.BLUE];
      case EnvironmentState.PENDING_TERMINATION:
        return ["Terminating...", LedIndicatorColor.BLUE];
      case EnvironmentState.TERMINATED:
        return ["Terminated", LedIndicatorColor.GRAY];
      case EnvironmentState.UNKNOWN:
      default:
        return ["Unknown", LedIndicatorColor.YELLOW];
    }
  }, [state]);
  return (
    <Container style={style}>
      <Typography variant="inherit" style={{ marginRight: 8 }}>
        {label}
      </Typography>
      <LedIndicator color={color} scale={0.2} />
    </Container>
  );
};
