import * as React from "react";
import { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { validateStringField } from "../../../utils/Validation";
import { DateTimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { AUTH_TOKEN_TYPES } from "../../../services/registry/TokenManagementService";

export interface TokenManagementCreateFormData {
  tokenType: string | undefined;
  token: string | undefined;
  expiresAt: number | undefined;
}

export interface TokenManagementCreateFormProps {
  visible: boolean;
  onAccept: (data: TokenManagementCreateFormData) => Promise<void>;
  onDismiss: () => void;
}

const TokenManagementCreateForm = ({ visible, onAccept, onDismiss }: TokenManagementCreateFormProps) => {
  const [loading, setLoading] = useState(false);
  const [tokenTypeError, setTokenTypeError] = useState<string | undefined>(undefined);
  const [tokenError, setTokenError] = useState<string | undefined>(undefined);
  const [expiration, setExpiration] = useState<DateTime | null>(null);

  const refTokenType = useRef<TextFieldProps>(null);
  const refToken = useRef<TextFieldProps>(null);

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const okButtonClickHandler = async () => {
    if (!refTokenType.current || !refToken.current || !expiration) {
      return;
    }

    const tokenType = validateStringField(
      refTokenType.current.value as AUTH_TOKEN_TYPES,
      setTokenTypeError,
      1,
      100,
    ) as string;
    const token = validateStringField(refToken.current.value as string, setTokenError, 30, 100);
    const expiresAt = expiration.toUnixInteger();

    if (!tokenType || !token || !expiresAt) {
      return;
    }

    const data: TokenManagementCreateFormData = { tokenType, token, expiresAt };

    setLoading(true);
    await onAccept(data);
    setLoading(false);
  };

  const tokenTypes = () => {
    return Object.values(AUTH_TOKEN_TYPES) as string[];
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler}>
      <DialogTitle>Extend Token Expiration</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill up the information below:</DialogContentText>
        <FormControl sx={{ m: 1, minWidth: 540 }}>
          <InputLabel id="data-structure-label">Token Type</InputLabel>
          <Select
            labelId="token-type-label"
            id="token-type-selector"
            inputRef={refTokenType}
            label="Token Type"
            error={tokenTypeError !== undefined}
            defaultValue=""
          >
            <MenuItem key="default" value="">
              Select token type
            </MenuItem>
            {tokenTypes().map((tokenType) => (
              <MenuItem key={tokenType} value={tokenType}>
                {tokenType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 540 }}>
          <TextField
            margin="dense"
            fullWidth
            variant="standard"
            inputRef={refToken}
            id="key"
            label="Token"
            type="text"
            error={tokenError !== undefined}
            helperText={tokenError}
          />
        </FormControl>
        <FormControl sx={{ m: 1, minWidth: 540 }}>
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateTimePicker defaultValue={DateTime.now()} label="Expiration" onChange={(value) => setExpiration(value)} />
          </LocalizationProvider>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} color="error" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={okButtonClickHandler} disabled={loading}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TokenManagementCreateForm;
