import * as React from "react";
import { ChangeEvent, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { validateCommitHashField, validateStringField } from "../../../utils/Validation";
import { IClientBuild } from "../../../services/registry/ClientBuildService";
import { CircularProgress } from "@mui/material";

export type ClientBuildFormType = "new" | "edit";

export interface ClientBuildFormData {
  id: string;
  name: string;
  version: string;
  branch: string;
  platform: string;
  commitHash: string;
}

export interface ClientBuildFormProps {
  visible: boolean;
  type: ClientBuildFormType;
  initialData?: IClientBuild;
  onDismiss: () => void;
  onAccept: (data: ClientBuildFormData) => Promise<void>;
}

export const ClientBuildFormField = (props: Omit<TextFieldProps, "variant">) => {
  return <TextField autoFocus margin="dense" fullWidth variant="standard" {...props} />;
};

export const ClientBuildForm = ({ visible, type, initialData, onDismiss, onAccept }: ClientBuildFormProps) => {
  const [loading, setLoading] = useState(false);

  const refName = useRef<TextFieldProps>(null);
  const refVersion = useRef<TextFieldProps>(null);
  const refBranch = useRef<TextFieldProps>(null);
  const refPlatform = useRef<TextFieldProps>(null);
  const refCommitHash = useRef<TextFieldProps>(null);

  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [versionError, setVersionError] = useState<string | undefined>(undefined);
  const [branchError, setBranchError] = useState<string | undefined>(undefined);
  const [platformError, setPlatformError] = useState<string | undefined>(undefined);
  const [commitHashError, setCommitHashError] = useState<string | undefined>(undefined);

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const validateName = (value: string) => validateStringField(value, setNameError, 3, 999);
  const validateVersion = (value: string) => validateStringField(value, setVersionError, 1, 999);
  const validateBranch = (value: string) => validateStringField(value, setBranchError, 1, 999);
  const validatePlatform = (value: string) => validateStringField(value, setPlatformError, 1, 999);
  const validateCommitHash = (value: string) => validateCommitHashField(value, setCommitHashError, "commitHash");

  const nameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => nameError && validateName(event.target.value);
  const versionChangeHandler = (event: ChangeEvent<HTMLInputElement>) => versionError && validateVersion(event.target.value);
  const branchChangeHandler = (event: ChangeEvent<HTMLInputElement>) => branchError && validateBranch(event.target.value);
  const platformChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    platformError && validatePlatform(event.target.value);
  const commitHashChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    commitHashError && validateCommitHash(event.target.value);

  const okButtonClickHandler = async () => {
    if (!refName.current || !refVersion.current || !refBranch.current || !refPlatform.current || !refCommitHash.current) {
      return;
    }

    const id = initialData?.id ?? "";
    const name = validateName(refName.current.value as string);
    const version = validateVersion(refVersion.current.value as string);
    const branch = validateBranch(refBranch.current.value as string);
    const platform = validatePlatform(refPlatform.current.value as string);
    const commitHash = validateCommitHash(refCommitHash.current.value as string);
    if (!name || !version || !branch || !platform || !commitHash) {
      return;
    }

    setLoading(true);
    await onAccept({ id, name, version, branch, platform, commitHash });
    setLoading(false);
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler}>
      <DialogTitle>{(type === "new" ? "New" : "Edit") + " Client Build"}</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <DialogContentText>Please fill up the information below:</DialogContentText>
            <ClientBuildFormField
              inputRef={refName}
              id="name"
              label="Name"
              type="text"
              defaultValue={initialData?.name}
              error={nameError !== undefined}
              helperText={nameError}
              onChange={nameChangeHandler}
            />
            <ClientBuildFormField
              inputRef={refVersion}
              id="version"
              label="Version"
              type="text"
              defaultValue={initialData?.version}
              error={versionError !== undefined}
              helperText={versionError}
              onChange={versionChangeHandler}
            />
            <ClientBuildFormField
              inputRef={refBranch}
              id="branch"
              label="Branch"
              type="text"
              defaultValue={initialData?.branch}
              error={branchError !== undefined}
              helperText={branchError}
              onChange={branchChangeHandler}
            />
            <ClientBuildFormField
              inputRef={refPlatform}
              id="platform"
              label="Platform"
              type="text"
              defaultValue={initialData?.platform}
              error={platformError !== undefined}
              helperText={platformError}
              onChange={platformChangeHandler}
            />
            <ClientBuildFormField
              inputRef={refCommitHash}
              id="commitHash"
              label="Commit Hash"
              type="text"
              defaultValue={initialData?.commitHash}
              error={commitHashError !== undefined}
              helperText={commitHashError}
              onChange={commitHashChangeHandler}
              disabled={type !== "new"}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} color="error" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={okButtonClickHandler} disabled={loading}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};
