import { useAuthUser } from "react-auth-kit";
import { buildGetOptions, buildPostOptions, fetchWithErrorHandling } from "../FetchUtils";
import { getRegistryURL } from "../registry/Registry";

export interface UsedCustomFieldTypeConfigOption {
  id: string;
  name?: string;
  label?: string;
  color: string;
  orderindex: number;
}

export interface IGeneratedArtIdData {
  AssetID: string;
  Root: string;
  Category: string;
  SubCategory: string;
  ModelVariant: string;
  TextureVariant: string;
  BundleID?: string;
  FriendlyName?: string;
  ClickupName?: string;
  Reference?: string;
  TaskType?: string;
  RootParent?: string;
  Tags?: string;
  Status?: string;
  Complexity?: string;
  NewRigg?: string;
  IsSkinned?: string;
  IsAnimated?: string;
  NeedsFX?: string;
  ClickupTaskLink?: string;
  ClickupTaskID?: string;
  ClickupWebhookID?: string;
  SyncSketchLink?: string;
  SyncSketchStatus?: string;
  DepotPathPerforce?: string;
  UnityAssetPath?: string;
  ReleaseVersion?: string;
  ReleaseDate?: string;
  LastBuildDate?: string;
  LastBuildStatus?: string;
  SubCategoryId?: string;
  Suffix?: string;
}

export const getSubcategories = async (accessToken: string) => {
  return fetchWithErrorHandling(
    getRegistryURL() + "/v1/asset-tooling/art-id-generator/subcategories",
    buildGetOptions(accessToken),
  );
};

export const getUsedCustomFields = async (accessToken: string) => {
  return fetchWithErrorHandling(
    getRegistryURL() + "/v1/asset-tooling/art-id-generator/used-custom-fields",
    buildGetOptions(accessToken),
  );
};

export const generateIds = async (
  accessToken: string,
  subcategory: string,
  startModel: number,
  endModel: number,
  numberOfIds: number,
  complexity: string,
  taskType: string,
  customFieldSubcategory: string,
) => {
  const options = buildPostOptions(accessToken, {
    subcategory,
    numberOfIds,
    startModel,
    endModel,
    complexityId: complexity,
    taskTypeId: taskType,
    subcategoryId: customFieldSubcategory,
  });
  return fetchWithErrorHandling(getRegistryURL() + "/v1/asset-tooling/art-id-generator/generate-ids", options);
};

export const sendClickupPending = async (accessToken: string) => {
  const options = buildPostOptions(accessToken, {});
  return fetchWithErrorHandling(getRegistryURL() + "/v1/asset-tooling/art-id-generator/send-clickup-pending", options);
};

export const sendClickup = async (accessToken: string, assetId: string) => {
  const options = buildPostOptions(accessToken, {});
  return fetchWithErrorHandling(getRegistryURL() + `/v1/asset-tooling/art-id-generator/send-clickup/${assetId}`, options);
};

export const list = async (accessToken: string) => {
  return fetchWithErrorHandling(getRegistryURL() + "/v1/asset-tooling/art-id-generator/list", buildGetOptions(accessToken));
};

export const syncClickup = (accessToken: string, assetId: string) => {
  const options = buildPostOptions(accessToken, {});
  return fetchWithErrorHandling(getRegistryURL() + `/v1/asset-tooling/art-id-generator/sync-clickup/${assetId}`, options);
};

export const reloadSubcategories = async (accessToken: string) => {
  const options = buildPostOptions(accessToken, {});
  return fetchWithErrorHandling(getRegistryURL() + `/v1/asset-tooling/art-id-generator/reload-subcategories`, options);
};

export const useAssetToolingService = () => {
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;

  return {
    getSubcategories: () => getSubcategories(accessToken),
    getUsedCustomFields: () => getUsedCustomFields(accessToken),
    generateIds: (
      subcategory: string,
      startModel: number,
      endModel: number,
      numberOfIds: number,
      complexity: string,
      taskType: string,
      customFieldSubcategory: string,
    ) =>
      generateIds(accessToken, subcategory, startModel, endModel, numberOfIds, complexity, taskType, customFieldSubcategory),
    list: () => list(accessToken),
    sendClickupPending: () => sendClickupPending(accessToken),
    sendClickup: (assetId: string) => sendClickup(accessToken, assetId),
    syncClickup: (assetId: string) => syncClickup(accessToken, assetId),
    reloadSubcategories: () => reloadSubcategories(accessToken),
  };
};
