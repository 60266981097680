import { Dispatch, SetStateAction } from "react";

export const formatError = (
  message: string,
  setError: Dispatch<SetStateAction<string | undefined>>,
  fieldName?: string | undefined,
) => {
  if (fieldName) {
    setError(`Field "${fieldName}" ${message.charAt(0).toLowerCase()}${message.slice(1)}`);
  } else {
    setError(`${message.charAt(0).toUpperCase()}${message.slice(1)}`);
  }
};

const playFabIdPattern = /^[A-F0-9]{15,16}$/;
export const isValidPlayFabId = (id: string) => {
  return id && playFabIdPattern.test(id);
};

export const validateStringField = (
  value: string | undefined,
  setError: Dispatch<SetStateAction<string | undefined>>,
  minLength: number,
  maxLength: number,
  fieldName?: string,
): string | undefined => {
  if (!value) {
    formatError("Must be set.", setError, fieldName);
    return undefined;
  } else if (value.length < minLength) {
    formatError(`Must be at least ${minLength} characters.`, setError, fieldName);
    return undefined;
  } else if (value.length > maxLength) {
    formatError(`Must be at most ${maxLength} characters.`, setError, fieldName);
    return undefined;
  } else {
    setError(undefined);
    return value;
  }
};

export const validateNameField = (
  value: string | undefined,
  setError: Dispatch<SetStateAction<string | undefined>>,
  minLength: number,
  maxLength: number,
  fieldName?: string,
): string | undefined => {
  const regexp = /^[a-zA-Z0-9_-]+$/;
  if (!value) {
    formatError("Must be set.", setError, fieldName);
    return undefined;
  } else if (value.match(regexp) === null) {
    formatError(`Must contain only alphanumeric, "-" and "_".`, setError, fieldName);
    return undefined;
  } else if (value.length < minLength) {
    formatError(`Must be at least ${minLength} characters.`, setError, fieldName);
    return undefined;
  } else if (value.length > maxLength) {
    formatError(`Must be at most ${maxLength} characters.`, setError, fieldName);
    return undefined;
  } else {
    setError(undefined);
    return value;
  }
};

export const validateStrongPasswordField = (
  value: string | undefined,
  setError: Dispatch<SetStateAction<string | undefined>>,
  fieldName?: string,
): string | undefined => {
  value = validateStringField(value, setError, 24, 48);
  if (value) {
    const lcPattern = /[a-z]/; // Regex to detect at least one lower-case letter
    if (!lcPattern.test(value)) {
      formatError("Must have at least one lower-case letter.", setError, fieldName);
      return undefined;
    }
    const upPattern = /[A-Z]/; // Regex to detect at least one upper-case letter
    if (!upPattern.test(value)) {
      formatError("Must have at least one upper-case letter.", setError, fieldName);
      return undefined;
    }
    const dPattern = /\d/; // Regex to detect at least one digit
    if (!dPattern.test(value)) {
      formatError("Must have at least one digit.", setError, fieldName);
      return undefined;
    }
    const scPattern = /[\W_]/; // Regex to detect at least one special character
    if (!scPattern.test(value)) {
      formatError("Must have at least one special character.", setError, fieldName);
      return undefined;
    }
    return value;
  }
};

export const validateUrlField = (
  value: string,
  setError: Dispatch<SetStateAction<string | undefined>>,
  fieldName?: string,
): string | undefined => {
  if (!value) {
    formatError("Must be set.", setError, fieldName);
    return undefined;
  } else {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
        "(\\#[-a-z\\d_]*)?$",
      "i",
    ); // validate fragment locator
    if (!urlPattern.test(value)) {
      formatError("Must be a valid URL.", setError, fieldName);
      return undefined;
    } else {
      setError(undefined);
      return value;
    }
  }
};

export const validateHexField = (
  value: string | undefined,
  setError: Dispatch<SetStateAction<string | undefined>>,
  minLength: number,
  maxLength: number,
  fieldName?: string,
): string | undefined => {
  value = validateStringField(value, setError, minLength, maxLength);
  if (value) {
    const hexPattern = /[0-9A-Fa-f]{6}/g;
    if (!hexPattern.test(value)) {
      formatError("Must be a valid hexadecimal string.", setError, fieldName);
      return undefined;
    } else {
      return value;
    }
  }
};

export const validateIntegerField = (
  value: string,
  setError: Dispatch<SetStateAction<string | undefined>>,
  min: number,
  max: number,
  fieldName?: string,
): number | undefined => {
  if (!value) {
    formatError("Must be set.", setError, fieldName);
    return undefined;
  } else {
    const integer = parseInt(value);
    if (isNaN(integer)) {
      formatError("Must be a valid integer.", setError, fieldName);
      return undefined;
    } else if (integer < min) {
      formatError(`Must be greater or equal to ${min}.`, setError, fieldName);
      return undefined;
    } else if (integer > max) {
      formatError(`Must be lower or equal to ${max}.`, setError, fieldName);
      return undefined;
    } else {
      setError(undefined);
      return integer;
    }
  }
};

export const validateSelectField = (
  value: string | undefined,
  setError: Dispatch<SetStateAction<string | undefined>>,
  options: string[],
  fieldName?: string,
): string | undefined => {
  if (!value) {
    formatError("Must be set.", setError, fieldName);
    return undefined;
  } else if (!options.includes(value)) {
    formatError("Must be an available option.", setError, fieldName);
    return undefined;
  } else {
    setError(undefined);
    return value;
  }
};

export const validateTfidField = (
  value: string | undefined,
  setError: Dispatch<SetStateAction<string | undefined>>,
  fieldName?: string,
): string | undefined => {
  if (!value) {
    formatError("Must be set.", setError, fieldName);
    return undefined;
  } else {
    const tfidPattern = /[0-9a-z]{13}/g;
    if (!tfidPattern.test(value)) {
      formatError("Must be a valid Townsfolk ID (compact format).", setError, fieldName);
      return undefined;
    } else {
      return value;
    }
  }
};

export const validateCommitHashField = (
  value: string | undefined,
  setError: Dispatch<SetStateAction<string | undefined>>,
  fieldName?: string,
): string | undefined => {
  if (!value) {
    formatError("Must be set.", setError, fieldName);
    return undefined;
  } else {
    const pattern = /^[a-fA-F0-9]{7,40}$/;
    if (!pattern.test(value)) {
      formatError("Must be a valid commit hash.", setError, fieldName);
      return undefined;
    } else {
      return value;
    }
  }
};
