/* eslint-disable  @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { JsonSchemaEditor } from "@quiet-front-end/json-schema-editor-antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AlertColor,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { CreateDataStructureProps, EditDataStructureProps } from "views/DataSchema/DataSchemaEditor";
import { DataStructureCategory, useDataSchemaService } from "../../services/backend/DataSchemaService";
import { LoadingOverlay } from "components/LoadingOverlay";
import { Notify } from "components/Notify";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import { useDataPersistenceService } from "services/backend/DataService";
import * as React from "react";

const defaultJsonData = {
  type: "object",
  properties: {
    id: {
      type: "string",
    },
    version: {
      type: "integer",
    },
    schemaVersion: {
      type: "integer",
    },
  },
  required: ["id", "version", "schemaVersion"],
  additionalProperties: false,
};

const DataStructureEditor = () => {
  const [jsonData, setJsonData] = useState(JSON.stringify(defaultJsonData));
  const { state } = useLocation();
  const navigate = useNavigate();
  const dataSchemaService = useDataSchemaService();
  const dataService = useDataPersistenceService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const tfidTypeDynamicStart = 256;
  const tfidTypeDynamicEnd = 1023;
  const [name, setName] = useState("");
  const [readAccessGrants, setReadAccessGrants] = useState("");
  const [readAccessGrantsError, setReadAccessGrantsError] = useState<string | undefined>(undefined);
  const [writeAccessGrants, setWriteAccessGrants] = useState("");
  const [writeAccessGrantsError, setWriteAccessGrantsError] = useState<string | undefined>(undefined);
  const [selectedPersistenceLayerName, setSelectedPersistenceLayerName] = useState<string[]>([]);
  const [selectedPersistenceLayerType, setSelectedPersistenceLayerType] = useState<number[]>([]);
  const [persistenceLayers, setPersistenceLayers] = useState<{ name: string; type: number }[]>([]);
  const [persistenceLayerError, setPersistenceLayerError] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (state.schema) {
      setJsonData(JSON.stringify(state.schema));
    }
    if (isEdit(state)) {
      setName(state.name);
      setReadAccessGrants(state.readAccessGrants?.join(",") || "public");
      setWriteAccessGrants(state.writeAccessGrants?.join(",") || "public");
    } else {
      setReadAccessGrants("public");
      setWriteAccessGrants("public");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const handleCloseClick = () => {
    navigate(-1);
  };
  function isEdit(object: any): object is EditDataStructureProps {
    return "name" in object;
  }
  const checkAccessGrants = (accessGrants: string, setError: Dispatch<SetStateAction<string | undefined>>): boolean => {
    if (!accessGrants || accessGrants.trim().length === 0) {
      setError("A value is required.");
      return false;
    }
    for (const grant of accessGrants.split(",").map((t) => t.trim())) {
      if (
        grant !== "public" &&
        !grant.startsWith("player:") &&
        !grant.startsWith("playerGroup:") &&
        !grant.startsWith("inherit:")
      ) {
        setError(`The specified access grant "${grant}" is invalid`);
        return false;
      }
    }
    setError(undefined);
    return true;
  };

  const checkPersistenceLayers = (setError: Dispatch<SetStateAction<string | undefined>>): boolean => {
    if (!selectedPersistenceLayerType || selectedPersistenceLayerType.length === 0) {
      setError("Select at least one type of Persistence Layer");
      return false;
    }
    return true;
  };

  const handleSaveClick = async () => {
    if (!checkAccessGrants(readAccessGrants, setReadAccessGrantsError)) {
      return;
    }
    if (!checkAccessGrants(writeAccessGrants, setWriteAccessGrantsError)) {
      return;
    }

    if (!checkPersistenceLayers(setPersistenceLayerError)) {
      return;
    }

    try {
      setIsLoading(true);
      let msg: string;
      if (isEdit(state)) {
        await dataSchemaService.updateDataStructure(
          state.branch,
          state.name,
          state.category,
          state.tfidType,
          state.persistenceLayers,
          JSON.parse(JSON.stringify(jsonData)),
          readAccessGrants.split(",").map((t) => t.trim()),
          writeAccessGrants.split(",").map((t) => t.trim()),
        );
        msg = `Data-structure ${name} updated successfully!`;
      } else {
        if (!name) {
          setAlertMessage("Name is required.");
          setAlertSeverity("warning");
          return;
        }
        const tfidType = findFreeTfidType((state as CreateDataStructureProps).tfidTypes);
        await dataSchemaService.createDataStructure(
          (state as CreateDataStructureProps).branch,
          name,
          DataStructureCategory.KEY_VALUE,
          tfidType,
          selectedPersistenceLayerType.map((type) => {
            return { type };
          }),
          JSON.parse(JSON.stringify(jsonData)),
          readAccessGrants.split(",").map((t) => t.trim()),
          writeAccessGrants.split(",").map((t) => t.trim()),
        );
        msg = `Data-structure ${name} created successfully!`;
      }
      setAlertMessage(msg);
      setAlertSeverity("success");
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not save the data-structure");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };

  // merges the input with the default json schema containing the required properties
  const handleOnChange = async (data: any) => {
    try {
      const properties = {
        ...defaultJsonData.properties,
        ...data.properties,
      };
      const required = Array.from(new Set([...defaultJsonData.required, ...data.required]));
      const merged = {
        ...data,
        properties,
        required,
        additionalProperties: defaultJsonData.additionalProperties,
      };
      setJsonData(merged);
    } catch (e) {
      console.log(e);
    }
  };

  const findFreeTfidType = (existingTfidtypes: number[]): number => {
    const set = new Set(existingTfidtypes);

    for (let i = tfidTypeDynamicStart; i <= tfidTypeDynamicEnd; i++) {
      if (!set.has(i)) {
        return i;
      }
    }
    return -1;
  };

  const fetchPersistenceLayers = async () => {
    try {
      setIsLoading(true);
      const { list } = await dataService.loadAllPersistenceLayers();
      setPersistenceLayers(list);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not fetch the list of persistence layers.");
      setAlertSeverity("error");
      setPersistenceLayers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePersistenceLayerChange = (event: SelectChangeEvent<typeof selectedPersistenceLayerName>) => {
    const {
      target: { value },
    } = event;

    const persistenceLayerNames = typeof value === "string" ? value.split(",") : value;
    const persistenceLayerTypes: number[] = persistenceLayerNames
      .map((name) => {
        const layer = persistenceLayers.find((layer) => layer.name === name);
        if (layer && layer.type !== undefined) {
          return layer.type;
        }
        return undefined;
      })
      .filter((value) => value !== undefined) as number[];

    setSelectedPersistenceLayerName(persistenceLayerNames);
    setSelectedPersistenceLayerType(persistenceLayerTypes);
  };

  useEffect(() => {
    (async () => fetchPersistenceLayers())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} justifyItems={"center"} container direction="row">
          <Typography variant="h5" color={"GrayText"} sx={{ marginTop: 1 }}>
            <TextField
              label="Structure Name"
              required
              id="structure-name"
              value={name}
              disabled={isEdit(state)}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
            />
          </Typography>
          <Typography variant="h5" color={"GrayText"} sx={{ marginLeft: 2, marginTop: 1 }}>
            <TextField
              label="Read Access Grants (comma separated)"
              id="read-access-grants"
              value={readAccessGrants}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setReadAccessGrants(event.target.value);
              }}
              variant={"filled"}
              sx={{ minWidth: 360 }}
              error={readAccessGrantsError !== undefined}
              helperText={readAccessGrantsError}
            />
          </Typography>
          <Typography variant="h5" color={"GrayText"} sx={{ marginLeft: 2, marginTop: 1 }}>
            <TextField
              label="Write Access Grants (comma separated)"
              id="write-access-grants"
              value={writeAccessGrants}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setWriteAccessGrants(event.target.value);
              }}
              variant={"filled"}
              sx={{ minWidth: 360 }}
              error={writeAccessGrantsError !== undefined}
              helperText={writeAccessGrantsError}
            />
          </Typography>
          <Typography variant="h5" color={"GrayText"} sx={{ marginLeft: 2, marginTop: 1 }}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="persistence-layer-checkbox-label">Persistence Layers</InputLabel>
              <Select
                labelId="persistence-layer-checkbox-label"
                id="persistence-layer-multiple-checkbox"
                multiple
                value={selectedPersistenceLayerName}
                onChange={handlePersistenceLayerChange}
                input={<OutlinedInput label="Persistence Layer" />}
                error={persistenceLayerError !== undefined}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
              >
                {persistenceLayers?.map((layer) => (
                  <MenuItem key={layer.name} value={layer.name}>
                    <Checkbox checked={selectedPersistenceLayerName.includes(layer.name)} />
                    <ListItemText primary={layer.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <JsonSchemaEditor jsonEditor={true} mock={false} data={jsonData} onChange={handleOnChange} />
        </Grid>
        <Grid item xs={11} textAlign={"end"}>
          <Button variant="contained" onClick={handleSaveClick}>
            Save
          </Button>
          <Button style={{ marginLeft: 4 }} variant="contained" onClick={handleCloseClick} color="error">
            Close
          </Button>
        </Grid>
      </Grid>
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
      {isLoading && <LoadingOverlay />}
    </>
  );
};

export default DataStructureEditor;
