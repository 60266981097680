/* eslint-disable  @typescript-eslint/no-explicit-any */
import {
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  AlertColor,
  Alert,
  Snackbar,
  Box,
} from "@mui/material";
import ChipsArray from "components/ChipsArray";
import JsonViewer from "components/JSONViewer";
import { LoadingOverlay } from "components/LoadingOverlay";
import MultipleSelectCheckmarks from "components/MultipleSelectCheckbox";
import { DateTime } from "luxon";
import { useState, useMemo, useEffect } from "react";
import { useLoggingService } from "services/backend/LoggingService";
import { stableSort, getComparator } from "utils/Table";
import VisibilityIcon from "@mui/icons-material/Visibility";

export interface LogEventTableProps {
  streamName: string;
  environmentName: string;
}
export default function LogEventTable({ streamName, environmentName }: LogEventTableProps) {
  const [logData, setLogData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [filledPages, setFilledPages] = useState<Set<number>>(new Set());
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [previousRowsPerPage, setPreviousRowsPerPage] = useState(5);
  const [statusList, setStatusList] = useState<Set<string>>(new Set());
  const [tagsList, setTagsList] = useState<Set<string>>(new Set());
  const [filteredStatus, setFilteredStatus] = useState<string[]>([]);
  const [filteredTags, setFilteredTags] = useState<string[]>([]);
  const [jsonViewerVisible, setJsonViewerVisible] = useState<boolean>(false);
  const [jsonData, setJsonData] = useState<string>("{}");
  const loggingService = useLoggingService();
  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [nextBackwardToken, setNextBackwardToken] = useState<string | null>(null);
  const [nextForwardToken, setNextForwardToken] = useState<string | null>(null);

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - logData.length) : 0;

  const filteredRows = useMemo(() => {
    return logData.filter((row) => {
      return (
        (filteredStatus.length === 0 || filteredStatus.includes(row.statusCode.toString())) &&
        (filteredTags.length === 0 || row.tags.some((tag: string) => filteredTags.includes(tag)))
      );
    });
  }, [logData, filteredStatus, filteredTags]);

  const visibleRows = useMemo(() => {
    return stableSort(filteredRows, getComparator("desc", "timestamp")).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [filteredRows, page, rowsPerPage]);

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPreviousRowsPerPage(rowsPerPage);
    setRowsPerPage(parseInt(event.target.value, 10));
    setNextBackwardToken(null);
    setLogData([]);
    setFilledPages(new Set());
    setPage(0);
  };

  const loadLog = async (stream: string) => {
    setLoading(true);
    try {
      if (environmentName) {
        const output = await loggingService.listEnvironmentEventLogs(
          environmentName,
          stream,
          nextBackwardToken,
          rowsPerPage,
        );
        const tagSet = new Set<string>();
        const statusSet = new Set<string>();
        const res = output.list.map((item: { message: string; timestamp: number }) => {
          const parsed = JSON.parse(item.message);
          parsed.tags.map((t: any) => tagSet.add(t));
          statusSet.add(parsed.statusCode.toString());
          return {
            ...item,
            statusCode: parsed.statusCode,
            tags: parsed.tags,
          };
        });
        setNextForwardToken(output.nextForwardToken);
        setNextBackwardToken(output.nextBackwardToken);
        setLogData([...logData, ...res]);
        setStatusList(new Set([...Array.from(statusList), ...Array.from(statusSet)]));
        setTagsList(new Set([...Array.from(tagsList), ...Array.from(tagSet)]));
      }
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not load the log file.");
      setAlertSeverity("error");
    }
    setLoading(false);
  };

  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, item: any) {
    e.stopPropagation();
    setJsonData(item.message);
    setJsonViewerVisible(true);
  }

  useEffect(() => {
    if (!filledPages.has(page) || previousRowsPerPage != rowsPerPage) {
      (async () => loadLog(streamName))();
      setFilledPages(filledPages.add(page));
      setPreviousRowsPerPage(rowsPerPage);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [rowsPerPage, page]);

  return (
    <Box sx={{ margin: 1 }}>
      <Toolbar sx={{ pl: { sm: 1 }, pr: { xs: 1, sm: 1 }, pt: { sm: 1 }, pb: { sm: 1 } }}>
        <MultipleSelectCheckmarks
          names={Array.from(statusList)}
          label="Status"
          setHandler={setFilteredStatus}
        ></MultipleSelectCheckmarks>
        <MultipleSelectCheckmarks
          names={Array.from(tagsList)}
          label="Tags"
          setHandler={setFilteredTags}
        ></MultipleSelectCheckmarks>
      </Toolbar>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>Tags</TableCell>
              <TableCell>Creation Time</TableCell>
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {visibleRows &&
              visibleRows.map((item: any) => {
                const timestamp = DateTime.fromMillis(item.timestamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);

                return (
                  <TableRow key={item.timestamp} style={{ backgroundColor: item.statusCode === 500 ? "#ef9a9a" : "white" }}>
                    <TableCell>{item.statusCode}</TableCell>
                    <TableCell>
                      <ChipsArray
                        data={[...item.tags]}
                        setChipData={() => {
                          return;
                        }}
                        showDelete={false}
                      />
                    </TableCell>
                    <TableCell>{timestamp}</TableCell>
                    <TableCell>
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<VisibilityIcon />}
                        onClick={(e) => {
                          handleClick(e, item);
                        }}
                      >
                        Show
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={-1}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={pageChangeHandler}
        onRowsPerPageChange={rowsPerPageChangeHandler}
        slotProps={{
          actions: {
            nextButton: {
              disabled:
                nextBackwardToken?.substring(2) == nextForwardToken?.substring(2) &&
                page == Math.max(...Array.from(filledPages)),
            },
          },
        }}
      />
      {loading && <LoadingOverlay />}
      <JsonViewer
        visible={jsonViewerVisible}
        data={JSON.stringify(JSON.parse(jsonData), null, 2)}
        onClose={() => setJsonViewerVisible(false)}
      ></JsonViewer>
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
