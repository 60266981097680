/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect, useState } from "react";
import { AlertColor } from "@mui/material";
import { AppContext } from "../../../utils/AppContext";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import { IManagedParameter, useManagedParameterService } from "../../../services/registry/ManagedParameterService";
import { Notify } from "../../../components/Notify";
import { ManagedParametersTable } from "./ManagedParametersTable";
import { ManagedParameterForm, ManagedParameterFormData, ManagedParameterFormType } from "./ManagedParameterForm";
import { YesNoPopup } from "../../../components/YesNoPopup";
import { ManagedParameterShow } from "./ManagedParameterShow";
import { downloadFile, fileNameFromContentDisposition } from "utils/Download";
import { useSearchParams } from "react-router-dom";

const ManagedParameters = () => {
  const managedParameterService = useManagedParameterService();

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [managedParameters, setManagedParameters] = useState<IManagedParameter[]>([]);
  const [selectedItem, setSelectedItem] = useState<IManagedParameter | undefined>(undefined);
  const [managedParameterFormVisible, setManagedParameterFormVisible] = useState(false);
  const [managedParameterFormType, setManagedParameterFormType] = useState<ManagedParameterFormType>("new");
  const [deleteParameterPopupVisible, setDeleteParameterPopupVisible] = useState(false);
  const [showParameterVisible, setShowParameterVisible] = useState(false);
  const [initialFilterQuery, setInitialFilterQuery] = useState<string>("");

  useEffect(() => {
    (async () => fetchManagedParameters())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchManagedParameters = async () => {
    try {
      setLoading(true);
      const { list } = await managedParameterService.listManagedParameters(undefined);
      setManagedParameters(list);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to list Managed Parameters.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const onManagedParameterAddButtonClick = () => {
    setManagedParameterFormType("new");
    setManagedParameterFormVisible(true);
  };

  const onDownloadProjectConfigClick = async () => {
    try {
      setLoading(true);
      const res = await managedParameterService.downloadProjectConfig();
      const contentDispositionHeader = res.headers.get("content-disposition");
      let fileName = "project-config.tfk";
      if (contentDispositionHeader) {
        const contentDispositionFileName = fileNameFromContentDisposition(contentDispositionHeader);
        fileName = contentDispositionFileName ? contentDispositionFileName : fileName;
      }

      const content = await res.blob();
      downloadFile(content, fileName);
    } catch (e: any) {
      setAlertMessage(e.message || "Failed to download config file.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const onManagedParameterEditButtonClick = () => {
    setManagedParameterFormType("edit");
    setManagedParameterFormVisible(true);
  };

  const managedParameterFormAcceptHandler = async (data: ManagedParameterFormData) => {
    if (managedParameterFormType === "new") {
      try {
        await managedParameterService.addManagedParameter(data.key, data.env, data.description, data.value, data.type);
        setManagedParameterFormVisible(false);
        await fetchManagedParameters();
      } catch (e: any) {
        setAlertMessage(e.debugMessage || "Could not add the new managed parameter.");
        setAlertSeverity("error");
      }
    } else {
      try {
        await managedParameterService.editManagedParameter(data.key, data.env, data.description, data.value, data.type);
        setManagedParameterFormVisible(false);
        await fetchManagedParameters();
      } catch (e: any) {
        setAlertMessage(e.debugMessage || "Could not edit the managed parameter.");
        setAlertSeverity("error");
      }
    }
  };

  const deleteParameterPopupYesHandler = async () => {
    if (!selectedItem) {
      return;
    }

    try {
      setLoading(true);
      setManagedParameterFormVisible(false);
      setDeleteParameterPopupVisible(false);

      await managedParameterService.removeManagedParameter(selectedItem.key, selectedItem.env);
      setSelectedItem(undefined);
      await fetchManagedParameters();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not remove managed parameter from the server build.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Managed Parameters");
  }, [context]);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const key = searchParams.get("key");
    if (key && managedParameters) {
      const item = managedParameters.find((t) => t.key == key);
      if (item) {
        setInitialFilterQuery(key);
      }
    }
  }, [searchParams, managedParameters]);

  return (
    <>
      <ManagedParametersTable
        rows={managedParameters}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        onShowButtonClick={() => setShowParameterVisible(true)}
        onAddButtonClick={onManagedParameterAddButtonClick}
        onEditButtonClick={onManagedParameterEditButtonClick}
        onRemoveButtonClick={() => setDeleteParameterPopupVisible(true)}
        onDownloadProjectConfigClick={onDownloadProjectConfigClick}
        initialFilterQuery={initialFilterQuery}
      />
      <ManagedParameterShow
        visible={showParameterVisible}
        data={selectedItem}
        onDismiss={() => setShowParameterVisible(false)}
      />
      <ManagedParameterForm
        visible={managedParameterFormVisible}
        type={managedParameterFormType}
        initialData={managedParameterFormType === "edit" ? selectedItem : undefined}
        onDismiss={() => setManagedParameterFormVisible(false)}
        onAccept={managedParameterFormAcceptHandler}
      />
      <YesNoPopup
        visible={deleteParameterPopupVisible}
        title={"Remove Managed Parameter"}
        message={`Are you sure you want to remove the Managed Parameter named <b>${selectedItem?.name}</b>?<br/>
        This Managed Parameter will become permanently unavailable if you remove it.`}
        onNo={() => setDeleteParameterPopupVisible(false)}
        onYes={deleteParameterPopupYesHandler}
      />
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
      {loading && <LoadingOverlay />}
    </>
  );
};

export default ManagedParameters;
