import { fetchWithErrorHandling, buildGetOptions, buildPostOptions } from "../FetchUtils";
import { useAuthUser } from "react-auth-kit";
import { getRegistryURL } from "./Registry";

const endpoint = (action: string): string => {
  return `${getRegistryURL()}/v1/registry/token-management/${action}`;
};

export enum AUTH_TOKEN_TYPES {
  TOOLING = "TOOLING",
  GAME_SERVER = "GAME_SERVER",
  GATEWAY = "GATEWAY",
}

export interface IAuthToken {
  token: string;
  expiresAt: number;
}

export interface IAuthTokenWithType extends IAuthToken {
  tokenType: AUTH_TOKEN_TYPES;
}

export const getTokens = (accessToken: string) => {
  return fetchWithErrorHandling(endpoint("list"), buildGetOptions(accessToken));
};

export const extendToken = (accessToken: string, tokenType: AUTH_TOKEN_TYPES, token: string, extension: number) => {
  return fetchWithErrorHandling(
    endpoint("extend"),
    buildPostOptions(accessToken, {
      tokenType,
      token,
      extension,
    }),
  );
};

export const createToken = (accessToken: string, tokenType: string, token: string, expiresAt: number) => {
  return fetchWithErrorHandling(
    endpoint("create"),
    buildPostOptions(accessToken, {
      tokenType,
      token,
      expiresAt,
    }),
  );
};

export const revokeToken = (accessToken: string, tokenType: AUTH_TOKEN_TYPES, token: string) => {
  return fetchWithErrorHandling(
    endpoint("revoke"),
    buildPostOptions(accessToken, {
      tokenType,
      token,
    }),
  );
};

export const removeToken = (accessToken: string, tokenType: AUTH_TOKEN_TYPES, token: string) => {
  return fetchWithErrorHandling(
    endpoint("remove"),
    buildPostOptions(accessToken, {
      tokenType,
      token,
    }),
  );
};

export const useTokenmanagementService = () => {
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;

  return {
    getTokens: () => getTokens(accessToken),
    extendToken: (tokenType: AUTH_TOKEN_TYPES, token: string, extension: number) =>
      extendToken(accessToken, tokenType, token, extension),
    createToken: (tokenType: string, token: string, expiresAt: number) =>
      createToken(accessToken, tokenType, token, expiresAt),
    revokeToken: (tokenType: AUTH_TOKEN_TYPES, token: string) => revokeToken(accessToken, tokenType, token),
    removeToken: (tokenType: AUTH_TOKEN_TYPES, token: string) => removeToken(accessToken, tokenType, token),
  };
};
