/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { AlertColor, Button, CircularProgress } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Notify } from "./Notify";
import { ServerImageBranchPicker } from "./ServerImageBranchPicker";

export interface ServerImageBranchPickerDialogProps {
  visible: boolean;
  dialogTitle: string;
  actionButtonLabel: string;
  onCancel: () => void;
  onAction: (branch: string) => Promise<void>;
}

export const ServerImageBranchPickerDialog = ({
  visible,
  dialogTitle,
  actionButtonLabel,
  onCancel,
  onAction,
}: ServerImageBranchPickerDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [branchError, setBranchError] = useState<string | undefined>(undefined);
  const [selectedBranch, setSelectedBranch] = useState<string | undefined>(undefined);

  const handleBranchSelected = (branch: string) => {
    setSelectedBranch(branch);
    setBranchError(undefined);
  };

  const actionButtonClickHandler = async () => {
    if (selectedBranch) {
      setLoading(true);
      try {
        await onAction(selectedBranch);
      } catch (e: any) {
        setAlertMessage(e.debugMessage || "Failed to perform action on the selected branch.");
        setAlertSeverity("error");
      } finally {
        setLoading(false);
      }
    } else {
      setBranchError("You must select a branch");
    }
  };

  return (
    <>
      <Dialog open={visible}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <ServerImageBranchPicker onBranchSelected={handleBranchSelected} error={branchError} />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel} color="error" disabled={loading}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={actionButtonClickHandler} disabled={loading}>
            {actionButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
    </>
  );
};
