/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { AlertColor, Button, CircularProgress } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { IServerImage } from "../services/registry/ServerImageService";
import { Notify } from "./Notify";
import { ServerImagePicker } from "./ServerImagePicker";

export interface ServerImagePickerDialogProps {
  visible: boolean;
  dialogTitle: string;
  actionButtonLabel: string;
  allowDefault?: boolean;
  onCancel: () => void;
  onAction: (serverImage: IServerImage | null) => Promise<void>;
}

export const ServerImagePickerDialog = ({
  visible,
  dialogTitle,
  actionButtonLabel,
  allowDefault,
  onCancel,
  onAction,
}: ServerImagePickerDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [serverImageError, setServerImageError] = useState<string | undefined>(undefined);
  const [selectedServerImage, setSelectedServerImage] = useState<IServerImage | null>(null);

  const handleServerImageSelected = (serverImage: IServerImage | null) => {
    setSelectedServerImage(serverImage);
    setServerImageError(undefined);
  };

  const actionButtonClickHandler = async () => {
    if (!allowDefault && !selectedServerImage) {
      setServerImageError("You must select a Server Image");
      return;
    }

    setLoading(true);
    try {
      await onAction(selectedServerImage);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to perform action on the selected server image.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog open={visible}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <ServerImagePicker
                onImageSelected={handleServerImageSelected}
                error={serverImageError}
                allowDefault={allowDefault}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel} color="error" disabled={loading}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={actionButtonClickHandler} disabled={loading}>
            {allowDefault && !selectedServerImage ? "Default" : actionButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
    </>
  );
};
