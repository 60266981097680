import * as React from "react";
import { useEffect, useState } from "react";
import { AlertColor, Box, CircularProgress, FormControl, FormHelperText, InputAdornment, Paper } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { useDataSchemaService } from "../services/backend/DataSchemaService";
import { Notify } from "./Notify";

export interface SchemaBranchPickerProps {
  onBranchSelected?: (branch: string) => void;
  error?: string;
}

export const SchemaBranchPicker = ({ onBranchSelected, error }: SchemaBranchPickerProps) => {
  const dataSchemaService = useDataSchemaService();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [branches, setBranches] = useState<string[]>([]);
  const [filteredBranches, setFilteredBranches] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const [selectedBranch, setSelectedBranch] = useState<string | undefined>(undefined);
  const [internalError, setInternalError] = useState<string | undefined>(undefined);

  useEffect(() => {
    fetchBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredBranches(branches.filter((branch) => branch.toLowerCase().includes(searchValue.toLowerCase())));
  }, [searchValue, branches]);

  const fetchBranches = async () => {
    setLoading(true);
    try {
      const res = await dataSchemaService.listDataSchemaBranches();
      // Extract the branches from the response
      const branchList = ["main"].concat(...res.list) || ["main"];
      setBranches(branchList);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to load schema branches.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const handleSelection = (branch: string) => {
    setSelectedBranch(branch);
    setInternalError(undefined);
    onBranchSelected?.(branch);
  };

  // Use external error if provided, otherwise use internal error state
  const displayError = error || internalError;

  return (
    <Box sx={{ width: "100%", mt: 1 }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <FormControl sx={{ width: "100%" }} error={!!displayError}>
          <TextField
            id="search-input"
            label="Search"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            style={{ marginBottom: 12, width: "100%" }}
            error={!!displayError}
          />

          <Paper elevation={0} style={{ maxHeight: 300, overflow: "auto" }}>
            <List dense disablePadding>
              {filteredBranches.map((branch) => (
                <ListItem key={branch} disableGutters>
                  <ListItemButton selected={selectedBranch === branch} onClick={() => handleSelection(branch)}>
                    <ListItemText primary={branch} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Paper>

          {displayError && <FormHelperText error>{displayError}</FormHelperText>}
        </FormControl>
      )}

      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
    </Box>
  );
};
