/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect, useState } from "react";
import { AlertColor } from "@mui/material";
import { AppContext } from "../../../utils/AppContext";
import { ServerImagesTable } from "./ServerImagesTable";
import { ServerImageForm, ServerImageFormData, ServerImageFormType } from "./ServerImageForm";
import { IServerImage, useServerImageService } from "../../../services/registry/ServerImageService";
import { IEnvironment } from "../../../services/registry/EnvironmentService";
import { EnvironmentPickerDialog } from "../../../components/EnvironmentPickerDialog";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import { YesNoPopup } from "../../../components/YesNoPopup";
import { Notify } from "../../../components/Notify";
import { useSearchParams } from "react-router-dom";

const ServerImages = () => {
  const serverImageService = useServerImageService();

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [serverImages, setServerImages] = useState<IServerImage[]>([]);
  const [selectedItem, setSelectedItem] = useState<IServerImage | undefined>(undefined);
  const [promoting, setPromoting] = useState(false);
  const [serverImageFormVisible, setServerImageFormVisible] = useState(false);
  const [serverImageFormType, setServerImageFormType] = useState<ServerImageFormType>("new");
  const [deleteServerPopupVisible, setDeleteServerPopupVisible] = useState<boolean>(false);
  const [initialFilterQuery, setInitialFilterQuery] = useState<string>("");

  useEffect(() => {
    (async () => fetchServerImages())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchServerImages = async () => {
    try {
      setLoading(true);
      const { list } = await serverImageService.listServerImages();
      setServerImages(list);
      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to get Server Images.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const onServerAddButtonClick = () => {
    setServerImageFormType("new");
    setServerImageFormVisible(true);
  };

  const onServerEditButtonClick = () => {
    setServerImageFormType("edit");
    setServerImageFormVisible(true);
  };

  const serverImageFormAcceptHandler = async (data: ServerImageFormData) => {
    setServerImageFormType("new");
    if (serverImageFormType === "new") {
      try {
        await serverImageService.addServerImage(data.name, data.version, data.branch);
        setServerImageFormVisible(false);
        await fetchServerImages();
      } catch (e: any) {
        setAlertMessage(e.debugMessage || "Could not add the new client build.");
        setAlertSeverity("error");
      }
    } else {
      try {
        await serverImageService.editServerImage(data.id, data.name, data.version, data.branch);
        setServerImageFormVisible(false);
        setSelectedItem({
          ...data,
          environment: selectedItem?.environment,
          creationDate: selectedItem?.creationDate ?? 0,
          commitHash: selectedItem?.commitHash,
        });
        await fetchServerImages();
      } catch (e: any) {
        setAlertMessage(e.debugMessage || "Could not edit the client build.");
        setAlertSeverity("error");
      }
    }
  };

  const promoteButtonClickHandler = async (environment: IEnvironment) => {
    if (!selectedItem) {
      return;
    }
    try {
      await serverImageService.promoteServerImage(selectedItem.id, environment.name);
      await fetchServerImages();
      setSelectedItem({ ...selectedItem, environment: environment.name });
      setPromoting(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not remove the selected Client Build.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const deleteServerPopupYesHandler = async () => {
    if (!selectedItem) {
      return;
    }
    try {
      setLoading(true);
      setDeleteServerPopupVisible(false);
      await serverImageService.removeServerImage(selectedItem.id);
      setSelectedItem(undefined);
      await fetchServerImages();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not remove the selected Client Build.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Server Images");
  }, [context]);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const id = searchParams.get("id");
    if (id && serverImages) {
      const item = serverImages.find((t) => t.id == id);
      if (item) {
        setInitialFilterQuery(id);
      }
    }
  }, [searchParams, serverImages]);

  return (
    <>
      <ServerImagesTable
        rows={serverImages}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        onAddButtonClick={onServerAddButtonClick}
        onEditButtonClick={onServerEditButtonClick}
        onRemoveButtonClick={() => setDeleteServerPopupVisible(true)}
        onPromoteButtonClick={() => setPromoting(true)}
        initialFilterQuery={initialFilterQuery}
      />
      <ServerImageForm
        visible={serverImageFormVisible}
        type={serverImageFormType}
        initialData={serverImageFormType === "edit" ? selectedItem : undefined}
        onDismiss={() => setServerImageFormVisible(false)}
        onAccept={serverImageFormAcceptHandler}
      />
      {promoting && (
        <EnvironmentPickerDialog
          visible={promoting}
          dialogTitle="Promote Server Image to Environment:"
          actionButtonLabel="Promote"
          onCancel={() => setPromoting(false)}
          onAction={promoteButtonClickHandler}
        />
      )}
      <YesNoPopup
        visible={deleteServerPopupVisible}
        title={"Remove Server Image"}
        message={`Are you sure you want to remove the Server Image named <b>${selectedItem?.name}</b>?<br/>
        This Server Image will become permanently unavailable if you remove it.`}
        onNo={() => setDeleteServerPopupVisible(false)}
        onYes={deleteServerPopupYesHandler}
      />
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
      {loading && <LoadingOverlay />}
    </>
  );
};

export default ServerImages;
