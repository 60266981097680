/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { AlertColor, Button, CircularProgress } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { IPackage } from "../services/registry/PackageService";
import { Notify } from "./Notify";
import { PackagePicker } from "./PackagePicker";

export interface PackagePickerDialogProps {
  visible: boolean;
  dialogTitle: string;
  actionButtonLabel: string;
  onCancel: () => void;
  onAction: (pkg: IPackage) => Promise<void>;
}

export const PackagePickerDialog = ({
  visible,
  dialogTitle,
  actionButtonLabel,
  onCancel,
  onAction,
}: PackagePickerDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [packageError, setPackageError] = useState<string | undefined>(undefined);
  const [selectedPackage, setSelectedPackage] = useState<IPackage | undefined>(undefined);

  const handlePackageSelected = (pkg: IPackage) => {
    setSelectedPackage(pkg);
    setPackageError(undefined);
  };

  const actionButtonClickHandler = async () => {
    if (selectedPackage) {
      setLoading(true);
      try {
        await onAction(selectedPackage);
      } catch (e: any) {
        setAlertMessage(e.debugMessage || "Failed to perform action on the selected package.");
        setAlertSeverity("error");
      } finally {
        setLoading(false);
      }
    } else {
      setPackageError("You must select a Package");
    }
  };

  return (
    <>
      <Dialog open={visible}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <PackagePicker onPackageSelected={handlePackageSelected} error={packageError} />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel} color="error" disabled={loading}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={actionButtonClickHandler} disabled={loading}>
            {actionButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
    </>
  );
};
