import { buildDeleteOptions, buildGetOptions, buildPostOptions, fetchWithErrorHandling } from "../FetchUtils";
import { getRegistryURL } from "./Registry";
import { useAuthUser } from "react-auth-kit";

const endpoint = (action: string): string => {
  return `${getRegistryURL()}/v1/registry/client-build/${action}`;
};

export interface IClientBuild {
  id: string;
  name: string;
  version: string;
  branch: string;
  platform: string;
  environment: string | undefined;
  creationDate: number;
  commitHash: string | undefined;
}

export const listClientBuilds = async (accessToken: string) => {
  return fetchWithErrorHandling(endpoint("list"), buildGetOptions(accessToken));
};

export const addClientBuild = async (
  accessToken: string,
  name: string,
  version: string,
  branch: string,
  platform: string,
  commitHash: string,
) => {
  const options = buildPostOptions(accessToken, { name, version, branch, platform, commitHash });
  return fetchWithErrorHandling(endpoint("add"), options);
};

export const editClientBuild = async (
  accessToken: string,
  id: string,
  name: string,
  version: string,
  branch: string,
  platform: string,
) => {
  const options = buildPostOptions(accessToken, { id, name, version, branch, platform });
  return fetchWithErrorHandling(endpoint("edit"), options);
};

export const removeClientBuild = async (accessToken: string, id: string) => {
  return fetchWithErrorHandling(endpoint(`remove/${id}`), buildDeleteOptions(accessToken));
};

export const promoteClientBuild = async (accessToken: string, clientBuildId: string, environmentName: string) => {
  const options = buildPostOptions(accessToken, { clientBuildId, environmentName });
  return fetchWithErrorHandling(endpoint("promote"), options);
};

export const useClientBuildService = () => {
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;

  return {
    listClientBuilds: () => listClientBuilds(accessToken),
    addClientBuild: (name: string, version: string, branch: string, platform: string, commitHash: string) =>
      addClientBuild(accessToken, name, version, branch, platform, commitHash),
    editClientBuild: (id: string, name: string, version: string, branch: string, platform: string) =>
      editClientBuild(accessToken, id, name, version, branch, platform),
    removeClientBuild: (id: string) => removeClientBuild(accessToken, id),
    promoteClientBuild: (clientBuildId: string, environmentName: string) =>
      promoteClientBuild(accessToken, clientBuildId, environmentName),
  };
};
