/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useRef, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Box from "@mui/material/Box";
import { AlertColor, Button, CircularProgress } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { IEnvironment } from "../services/registry/EnvironmentService";
import { Notify } from "./Notify";
import { EnvironmentPicker } from "./EnvironmentPicker";

export interface EnvironmentPickerDialogProps {
  visible: boolean;
  dialogTitle: string;
  actionButtonLabel: string;
  onCancel: () => void;
  onAction: (environment: IEnvironment) => Promise<void>;
}

export const EnvironmentPickerDialog = ({
  visible,
  dialogTitle,
  actionButtonLabel,
  onCancel,
  onAction,
}: EnvironmentPickerDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const refEnvironment = useRef<any>(null);
  const [environmentError, setEnvironmentError] = useState<string | undefined>(undefined);
  const [selectedEnvironment, setSelectedEnvironment] = useState<IEnvironment | undefined>(undefined);

  const handleEnvironmentSelected = (environment: IEnvironment) => {
    setSelectedEnvironment(environment);
    setEnvironmentError(undefined);
  };

  const actionButtonClickHandler = async () => {
    if (selectedEnvironment) {
      setLoading(true);
      try {
        await onAction(selectedEnvironment);
      } catch (e: any) {
        setAlertMessage(e.debugMessage || "Failed to perform action on the selected environment.");
        setAlertSeverity("error");
      } finally {
        setLoading(false);
      }
    } else if (!refEnvironment.current?.value) {
      setEnvironmentError("You must select an Environment");
    }
  };

  return (
    <>
      <Dialog open={visible}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <EnvironmentPicker
                selectRef={refEnvironment}
                error={environmentError}
                onEnvironmentSelected={handleEnvironmentSelected}
              />
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onCancel} color="error" disabled={loading}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={actionButtonClickHandler} disabled={loading}>
            {actionButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
    </>
  );
};
