/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect, useState } from "react";
import { AlertColor } from "@mui/material";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import { Notify } from "../../../components/Notify";
import { AppContext } from "../../../utils/AppContext";
import { useSystemService } from "../../../services/backend/SystemService";
import {
  AUTH_TOKEN_TYPES,
  IAuthTokenWithType,
  useTokenmanagementService,
} from "../../../services/registry/TokenManagementService";
import { TokenManagementTable } from "./TokenManagementTable";
import TokenManagementExtensionForm, { TokenManagementExtensionFormData } from "./TokenManagementExtensionForm";
import { YesNoPopup } from "../../../components/YesNoPopup";
import TokenManagementCreateForm, { TokenManagementCreateFormData } from "./TokenManagementCreateForm";

const TokenManagement = () => {
  const systemService = useSystemService();
  const tokenManagementService = useTokenmanagementService();

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [tokens, setTokens] = useState<IAuthTokenWithType[]>([]);
  const [selectedItem, setSelectedItem] = useState<IAuthTokenWithType | undefined>(undefined);
  const [tokenManagementExtensionFormVisible, setTokenManagementExtensionFormVisible] = useState(false);
  const [tokenManagementCreateFormVisible, setTokenManagementCreateFormVisible] = useState(false);
  const [revokeTokenPopupVisible, setRevokeTokenPopupVisible] = useState(false);
  const [removeTokenPopupVisible, setRemoveTokenPopupVisible] = useState(false);

  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Token Management");
    context?.setTargetAPI("registry");
  }, [context]);

  useEffect(() => {
    (async () => fetchTokens())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchTokens = async () => {
    try {
      setLoading(true);
      const { list } = await tokenManagementService.getTokens();
      setTokens(list);
      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to get tokens.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const reloadTokens = async () => {
    try {
      setLoading(true);
      await systemService.reloadTokens();
      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to reload tokens.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const extendToken = async (data: TokenManagementExtensionFormData) => {
    try {
      setLoading(true);
      if (!data.tokenType || !data.token || !data.extension) {
        return;
      }
      await tokenManagementService.extendToken(data.tokenType, data.token, data.extension);
      setTokenManagementExtensionFormVisible(false);
      await fetchTokens();
      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to extend token expiration.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
      setTokenManagementExtensionFormVisible(false);
    }
  };

  const createToken = async (data: TokenManagementCreateFormData) => {
    try {
      setLoading(true);
      if (!data.tokenType || !data.token || !data.expiresAt) {
        return;
      }
      await tokenManagementService.createToken(data.tokenType, data.token, data.expiresAt);
      setTokenManagementCreateFormVisible(false);
      await fetchTokens();
      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to create token.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
      setTokenManagementCreateFormVisible(false);
    }
  };

  const revokeToken = async (tokenType: AUTH_TOKEN_TYPES, token: string) => {
    try {
      setLoading(true);
      await tokenManagementService.revokeToken(tokenType, token);
      setRevokeTokenPopupVisible(false);
      await fetchTokens();
      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to revoke token.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
      setRevokeTokenPopupVisible(false);
    }
  };

  const removeToken = async (tokenType: AUTH_TOKEN_TYPES, token: string) => {
    try {
      setLoading(true);
      await tokenManagementService.removeToken(tokenType, token);
      setRemoveTokenPopupVisible(false);
      await fetchTokens();
      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to revoke token.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
      setRemoveTokenPopupVisible(false);
    }
  };

  const revokeTokenPopupYesHandler = async () => {
    if (selectedItem && selectedItem.tokenType && selectedItem.token) {
      await revokeToken(selectedItem.tokenType, selectedItem.token);
    }
  };

  const removeTokenPopupYesHandler = async () => {
    if (selectedItem && selectedItem.tokenType && selectedItem.token) {
      await removeToken(selectedItem.tokenType, selectedItem.token);
    }
  };

  return (
    <>
      <TokenManagementTable
        rows={tokens}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        onReloadTokensButtonClick={reloadTokens}
        onExtendTokenButtonClick={() => setTokenManagementExtensionFormVisible(true)}
        onCreateTokenButtonClick={() => setTokenManagementCreateFormVisible(true)}
        onRevokeTokenButtonClick={() => setRevokeTokenPopupVisible(true)}
        onRemoveTokenButtonClick={() => setRemoveTokenPopupVisible(true)}
      />
      <TokenManagementExtensionForm
        visible={tokenManagementExtensionFormVisible}
        initialData={{
          tokenType: selectedItem?.tokenType,
          token: selectedItem?.token,
          expiresAt: selectedItem?.expiresAt,
          extension: 1,
        }}
        onDismiss={() => setTokenManagementExtensionFormVisible(false)}
        onAccept={extendToken}
      />
      <TokenManagementCreateForm
        visible={tokenManagementCreateFormVisible}
        onDismiss={() => setTokenManagementCreateFormVisible(false)}
        onAccept={createToken}
      />
      <YesNoPopup
        visible={revokeTokenPopupVisible}
        title={"Revoke token"}
        message={`Are you sure you want to revoke token <b>${selectedItem?.token}</b>?<br/>
        If you revoke this token it will become invalid and requests to the backend might fail.`}
        onNo={() => setRevokeTokenPopupVisible(false)}
        onYes={revokeTokenPopupYesHandler}
      />
      <YesNoPopup
        visible={removeTokenPopupVisible}
        title={"Remove token"}
        message={`Are you sure you want to remove token <b>${selectedItem?.token}</b>?<br/>
        If you remove this token it will not be useable anymore. This action cannot be undone.`}
        onNo={() => setRemoveTokenPopupVisible(false)}
        onYes={removeTokenPopupYesHandler}
      />
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
      {loading && <LoadingOverlay />}
    </>
  );
};

export default TokenManagement;
