/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { ChangeEvent, useEffect, useState } from "react";
import { AlertColor, MenuItem } from "@mui/material";
import { IEnvironment, LOCAL_ENV, useEnvironmentService } from "../services/registry/EnvironmentService";
import { Notify } from "./Notify";
import TextField from "@mui/material/TextField";
import { isRunningLocally } from "../utils/Environment";
import { useSearchParams } from "react-router-dom";

export interface EnvironmentSelectorProps {
  id: string;
  selectedEnvironment: IEnvironment | undefined;
  onEnvironmentSelected: (env: IEnvironment | undefined) => void;
  errorMessage?: string;
  disabled?: boolean;
  useStorage?: boolean;
}

export const EnvironmentSelector = ({
  id,
  selectedEnvironment,
  onEnvironmentSelected,
  errorMessage,
  disabled,
  useStorage,
}: EnvironmentSelectorProps) => {
  const environmentService = useEnvironmentService();
  const [searchParams, setSearchParams] = useSearchParams();

  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [environments, setEnvironments] = useState<IEnvironment[]>([]);

  useEffect(() => {
    if (!selectedEnvironment) {
      if (useStorage) {
        const storedEnvironmentName = localStorage.getItem("selectedEnvironment");
        if (storedEnvironmentName) {
          const e = environments.find((e) => e.name === storedEnvironmentName);
          onEnvironmentSelected(e);
          return;
        }
      }
      const env = searchParams.get("env");
      if (env) {
        const e = environments.find((e) => e.name == env);
        if (e) {
          onEnvironmentSelected(e);
          return;
        }
      }
      if (isRunningLocally()) {
        onEnvironmentSelected(LOCAL_ENV);
      } else {
        for (const e of environments) {
          if (e.isDefault) {
            onEnvironmentSelected(e);
          }
        }
      }
    }
  }, [environments, selectedEnvironment, onEnvironmentSelected, searchParams, useStorage]);

  useEffect(() => {
    (async () => fetchEnvironments())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEnvironments = async () => {
    try {
      const { list } = await environmentService.listEnvironments();
      if (isRunningLocally()) {
        setEnvironments([LOCAL_ENV].concat(...list));
      } else {
        setEnvironments(list);
      }
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to load available environments.");
      setAlertSeverity("error");
    }
  };

  const environmentChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const e = environments.find((e) => e.name === event.target.value);
    onEnvironmentSelected(e);
    if (e) {
      setSearchParams((t) => {
        t.set("env", e.name);
        return t;
      });
      if (useStorage) {
        localStorage.setItem("selectedEnvironment", e.name);
      }
    }
  };

  return (
    <>
      <TextField
        id={`environment-selector-${id}`}
        select
        label="Environment"
        value={selectedEnvironment?.name || ""}
        onChange={environmentChangeHandler}
        sx={{
          background: "white",
          minWidth: "160px",
        }}
        variant="filled"
        error={errorMessage !== undefined}
        helperText={errorMessage}
        disabled={disabled}
      >
        {environments
          .filter((env) => env.terminationDate === undefined)
          .map((env) => (
            <MenuItem key={env.name} value={env.name}>
              {env.name}
            </MenuItem>
          ))}
      </TextField>
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
    </>
  );
};
