import * as React from "react";
import { useEffect, useState } from "react";
import { AlertColor, Box, CircularProgress, FormControl, FormHelperText, InputAdornment, Paper } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { DateTime } from "luxon";
import { IServerImage, useServerImageService } from "../services/registry/ServerImageService";
import { Notify } from "./Notify";

export interface ServerImagePickerProps {
  onImageSelected?: (serverImage: IServerImage | null) => void;
  error?: string;
  allowDefault?: boolean;
}

export const ServerImagePicker = ({ onImageSelected, error }: ServerImagePickerProps) => {
  const serverImageService = useServerImageService();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [serverImages, setServerImages] = useState<IServerImage[]>([]);
  const [filteredServerImages, setFilteredServerImages] = useState<IServerImage[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");

  const [selectedItem, setSelectedItem] = useState<IServerImage | undefined>(undefined);
  const [internalError, setInternalError] = useState<string | undefined>(undefined);

  useEffect(() => {
    fetchServerImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFilteredServerImages(
      serverImages.filter(
        (img) => img.name.includes(searchValue) || img.id.includes(searchValue) || img.branch.includes(searchValue),
      ),
    );
  }, [searchValue, serverImages]);

  const fetchServerImages = async () => {
    setLoading(true);
    try {
      const res = await serverImageService.listServerImages();
      const list = res.list as IServerImage[];
      const seen = new Set<string>();
      const sortedAndUnique = list
        .sort((a, b) => b.creationDate - a.creationDate)
        .filter((img) => (seen.has(img.name) ? false : seen.add(img.name)));
      setServerImages(sortedAndUnique);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to load backend images.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const handleSelection = (img: IServerImage) => {
    setSelectedItem(img);
    setInternalError(undefined);
    onImageSelected?.(img);
  };

  // Use external error if provided, otherwise use internal error state
  const displayError = error || internalError;

  return (
    <Box sx={{ width: "100%", mt: 1 }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <FormControl sx={{ width: "100%" }} error={!!displayError}>
          <TextField
            id="search-input"
            label="Search"
            variant="standard"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            style={{ marginBottom: 12, width: "100%" }}
            error={!!displayError}
          />

          <Paper elevation={0} style={{ maxHeight: 300, overflow: "auto" }}>
            <List dense disablePadding>
              {filteredServerImages.map((img) => {
                const creationDate = DateTime.fromSeconds(img.creationDate).toLocaleString(
                  DateTime.DATETIME_SHORT_WITH_SECONDS,
                );
                return (
                  <ListItem key={img.id} disableGutters>
                    <ListItemButton selected={selectedItem?.id === img.id} onClick={() => handleSelection(img)}>
                      <ListItemText primary={img.name} secondary={`[${img.id}] ${creationDate}`} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Paper>

          {displayError && <FormHelperText error>{displayError}</FormHelperText>}
        </FormControl>
      )}

      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
    </Box>
  );
};
