import * as React from "react";
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { DateTime } from "luxon";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Button, Chip, Stack } from "@mui/material";
import TablePagination from "@mui/material/TablePagination";
import Toolbar from "@mui/material/Toolbar";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import TableHead from "@mui/material/TableHead";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import { getComparator, Order, stableSort } from "../../../utils/Table";
import { IAuthTokenWithType } from "../../../services/registry/TokenManagementService";

interface HeadCell {
  id: keyof IAuthTokenWithType | string;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "token",
    label: "Token",
    padding: "none",
  },
  {
    id: "tokenType",
    label: "Type",
  },
  {
    id: "expiresAt",
    label: "Expires At",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "actions",
    label: "Actions",
    align: "center",
  },
];

interface TokenManagementTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IAuthTokenWithType | string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const TokenManagementTableHead = ({ order, orderBy, onRequestSort }: TokenManagementTableHeadProps) => {
  const createSortHandler = (property: keyof IAuthTokenWithType | string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface TokenManagementTableToolbarProps {
  onReloadTokensButtonClick: () => void;
  onCreateTokenButtonClick: () => void;
}

const TokenManagementTableToolbar = ({
  onReloadTokensButtonClick,
  onCreateTokenButtonClick,
}: TokenManagementTableToolbarProps) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Button size="small" variant="contained" startIcon={<RefreshIcon />} onClick={onReloadTokensButtonClick}>
          Reload Tokens
        </Button>
        <Button size="small" variant="contained" color="primary" startIcon={<AddIcon />} onClick={onCreateTokenButtonClick}>
          Create Token
        </Button>
      </Stack>
    </Toolbar>
  );
};

export interface TokenManagementTableProps {
  rows: IAuthTokenWithType[];
  selectedItem: IAuthTokenWithType | undefined;
  setSelectedItem: Dispatch<SetStateAction<IAuthTokenWithType | undefined>>;
  onReloadTokensButtonClick: () => void;
  onExtendTokenButtonClick: () => void;
  onCreateTokenButtonClick: () => void;
  onRevokeTokenButtonClick: () => void;
  onRemoveTokenButtonClick: () => void;
}

export const TokenManagementTable = ({
  rows,
  selectedItem,
  setSelectedItem,
  onReloadTokensButtonClick,
  onExtendTokenButtonClick,
  onCreateTokenButtonClick,
  onRevokeTokenButtonClick,
  onRemoveTokenButtonClick,
}: TokenManagementTableProps) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof IAuthTokenWithType | string>("expiresAt");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const now = Math.floor(Date.now() / 1000);

  const requestSortHandler = (event: MouseEvent<unknown>, property: keyof IAuthTokenWithType | string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return stableSort(rows, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [rows, order, orderBy, page, rowsPerPage]);

  const isSelected = (row: IAuthTokenWithType, selectedItem: IAuthTokenWithType | undefined) =>
    selectedItem !== undefined && row.token === selectedItem.token;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TokenManagementTableToolbar
          onReloadTokensButtonClick={onReloadTokensButtonClick}
          onCreateTokenButtonClick={onCreateTokenButtonClick}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="small">
            <TokenManagementTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={requestSortHandler}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `client-builds-table-checkbox-${index}`;
                const expirationDate = DateTime.fromSeconds(row.expiresAt).toLocaleString(
                  DateTime.DATETIME_SHORT_WITH_SECONDS,
                );
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    onClick={() => setSelectedItem(row)}
                    tabIndex={-1}
                    key={row.token}
                    selected={isSelected(row, selectedItem)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="normal" />
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.token}
                    </TableCell>
                    <TableCell align="left">{row.tokenType}</TableCell>
                    <TableCell align="left">{expirationDate}</TableCell>
                    <TableCell align="left">
                      {now > row.expiresAt ? (
                        <Chip size="small" label="EXPIRED" color="error" />
                      ) : (
                        <Chip size="small" label="ACTIVE" color="success" />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <Stack spacing={1} direction="row" justifyContent="flex-end">
                        <Button size="small" variant="outlined" onClick={onExtendTokenButtonClick}>
                          Extend Expiration
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          color="error"
                          onClick={onRevokeTokenButtonClick}
                          disabled={now > row.expiresAt}
                        >
                          Revoke
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          color="error"
                          onClick={onRemoveTokenButtonClick}
                          disabled={now < row.expiresAt}
                        >
                          Remove
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={pageChangeHandler}
          onRowsPerPageChange={rowsPerPageChangeHandler}
        />
      </Paper>
    </Box>
  );
};
