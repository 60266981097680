import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { ChangeEvent, useRef, useState } from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
import { validateIntegerField } from "../utils/Validation";

export interface IntegerInputPopupProps {
  visible: boolean;
  title: string;
  message?: string;
  defaultValue?: number;
  fieldName?: string;
  minValue?: number;
  maxValue?: number;
  onDismiss: () => void;
  onAccept: (value: number) => Promise<void>;
}

export const IntegerInputPopup = ({
  visible,
  title,
  message,
  defaultValue,
  fieldName,
  minValue,
  maxValue,
  onDismiss,
  onAccept,
}: IntegerInputPopupProps) => {
  const refValue = useRef<TextFieldProps>(null);
  const [valueError, setValueError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const valueChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (valueError) {
      validateIntegerField(event.target.value, setValueError, minValue ?? 0, maxValue ?? Number.MAX_SAFE_INTEGER);
    }
  };

  const popupCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const okButtonClickHandler = async () => {
    if (!refValue.current) {
      return;
    }

    const value = validateIntegerField(
      refValue.current.value as string,
      setValueError,
      minValue ?? 0,
      maxValue ?? Number.MAX_SAFE_INTEGER,
    );
    if (!value && value !== 0) {
      return;
    }

    setLoading(true);
    try {
      await onAccept(value);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={visible} onClose={popupCloseHandler}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-message"
          dangerouslySetInnerHTML={message !== undefined ? { __html: message } : undefined}
        />
        {loading ? (
          <CircularProgress />
        ) : (
          <TextField
            autoFocus
            margin="dense"
            fullWidth
            variant="standard"
            inputRef={refValue}
            id="alert-dialog-input-field"
            label={fieldName ?? "Value"}
            type="number"
            defaultValue={defaultValue}
            error={valueError !== undefined}
            helperText={valueError}
            onChange={valueChangeHandler}
            InputProps={{
              inputProps: {
                step: 1,
                min: minValue,
                max: maxValue,
              },
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss}>Cancel</Button>
        <Button onClick={okButtonClickHandler}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};
