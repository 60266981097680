/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect, useState } from "react";
import {
  AlertColor,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { IEnvironment, useEnvironmentService } from "../services/registry/EnvironmentService";
import { Notify } from "./Notify";

export interface EnvironmentPickerProps {
  onEnvironmentSelected?: (environment: IEnvironment) => void;
  selectRef?: React.Ref<any>;
  error?: string;
}

export const EnvironmentPicker = ({ onEnvironmentSelected, selectRef, error }: EnvironmentPickerProps) => {
  const environmentService = useEnvironmentService();

  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [environments, setEnvironments] = useState<IEnvironment[]>([]);

  useEffect(() => {
    fetchEnvironments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchEnvironments = async () => {
    setLoading(true);
    try {
      const { list } = await environmentService.listEnvironments();
      setEnvironments(list);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to load available environments.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const selectedEnvironmentName = event.target.value;
    const environment = environments.find((env) => env.name === selectedEnvironmentName);
    if (environment && onEnvironmentSelected) {
      onEnvironmentSelected(environment);
    }
  };

  return (
    <Box sx={{ width: "100%", mt: 1 }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <FormControl sx={{ width: "100%" }} error={!!error}>
          <InputLabel id="environment-label">Environment</InputLabel>
          <Select
            labelId="environment-label"
            id="environment-selector"
            defaultValue=""
            inputRef={selectRef}
            label="Environment"
            onChange={handleChange}
          >
            {environments
              .filter((env) => env.terminationDate === undefined)
              .map((env) => (
                <MenuItem key={env.name} value={env.name}>
                  {env.name}
                </MenuItem>
              ))}
          </Select>
          {error && <FormHelperText error>{error}</FormHelperText>}
        </FormControl>
      )}

      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
    </Box>
  );
};
