import * as React from "react";
import { useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { DateTime } from "luxon";
import { validateIntegerField } from "../../../utils/Validation";
import { AUTH_TOKEN_TYPES } from "../../../services/registry/TokenManagementService";

export interface TokenManagementExtensionFormData {
  tokenType: AUTH_TOKEN_TYPES | undefined;
  token: string | undefined;
  expiresAt: number | undefined;
  extension: number | undefined;
}

export interface TokenManagementExtensionFormProps {
  visible: boolean;
  initialData: TokenManagementExtensionFormData;
  onAccept: (data: TokenManagementExtensionFormData) => Promise<void>;
  onDismiss: () => void;
}

const TokenManagementExtensionForm = ({ visible, initialData, onAccept, onDismiss }: TokenManagementExtensionFormProps) => {
  const [loading, setLoading] = useState(false);
  const [extensionError, setExtensionError] = useState<string | undefined>(undefined);

  const refExtension = useRef<TextFieldProps>(null);

  const validateExtension = (value: string | undefined) => {
    if (!value) {
      setExtensionError("Extension cannot be empty");
      return;
    }

    return validateIntegerField(value, setExtensionError, 1, 365);
  };

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const okButtonClickHandler = async () => {
    if (!refExtension.current) {
      return;
    }

    const extensionInDays = validateExtension(refExtension.current.value as string);

    if (!extensionInDays) {
      return;
    }

    const extensionInSeconds = extensionInDays * 24 * 60 * 60;

    setLoading(true);
    await onAccept({
      tokenType: initialData.tokenType,
      token: initialData.token,
      expiresAt: initialData.expiresAt,
      extension: extensionInSeconds,
    });
    setLoading(false);
  };

  const currentExpiration = initialData.expiresAt
    ? DateTime.fromSeconds(initialData.expiresAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)
    : "";

  return (
    <Dialog open={visible} onClose={dialogCloseHandler}>
      <DialogTitle>Extend Token Expiration</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill up the information below:</DialogContentText>
        <DialogContentText>Token Type: {initialData.tokenType}</DialogContentText>
        <DialogContentText>Token: {initialData.token}</DialogContentText>
        <DialogContentText>Expiration: {currentExpiration}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          fullWidth
          variant="standard"
          inputRef={refExtension}
          id="extension"
          label="Extension (in days)"
          type="number"
          defaultValue="1"
          error={extensionError !== undefined}
          helperText={extensionError}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss} color="error" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={okButtonClickHandler} disabled={loading}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TokenManagementExtensionForm;
