/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { ChangeEvent } from "react";
import { MenuItem } from "@mui/material";
import TextField from "@mui/material/TextField";

export interface DeploymentLogSelectorProps {
  id: string;
  deploymentLogs: string[];
  selectedDeploymentLog: string | undefined;
  onDeploymentLogSelected: (env: string | undefined) => void;
  disabled?: boolean;
}

export const DeploymentLogSelector = ({
  id,
  deploymentLogs,
  selectedDeploymentLog,
  onDeploymentLogSelected,
  disabled,
}: DeploymentLogSelectorProps) => {
  const deploymentLogChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const logName = deploymentLogs.find((logName) => logName === event.target.value);
    onDeploymentLogSelected(logName);
  };

  return (
    <>
      <TextField
        id={`deploymentLog-selector-${id}`}
        select
        label="Deployment Logs"
        value={selectedDeploymentLog || ""}
        onChange={deploymentLogChangeHandler}
        sx={{
          background: "white",
          minWidth: "320px",
        }}
        variant="outlined"
        disabled={disabled}
        size="small"
      >
        {deploymentLogs.map((logName) => (
          <MenuItem key={logName} value={logName}>
            {logName}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};
