/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useAssetToolingService, UsedCustomFieldTypeConfigOption } from "../../services/asset/AssetToolingService";
import { AlertColor, FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { Notify } from "../../components/Notify";
import { LoadingOverlay } from "../../components/LoadingOverlay";
import * as React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import routes from "../../components/routing/Routes";
import Divider from "@mui/material/Divider";
import RefreshIcon from "@mui/icons-material/Refresh";

const ArtAssetsIdsGenerator = () => {
  const navigate = useNavigate();
  const assetToolingService = useAssetToolingService();

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [subcategories, setSubcategories] = useState<string[]>([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState<string>("");
  const [complexityOptions, setComplexityOptions] = useState<UsedCustomFieldTypeConfigOption[]>([]);
  const [taskTypeOptions, setTaskTypeOptions] = useState<UsedCustomFieldTypeConfigOption[]>([]);
  const [subcategoryOptions, setSubcategoryOptions] = useState<UsedCustomFieldTypeConfigOption[]>([]);
  const [selectedComplexity, setSelectedComplexity] = useState<string>("");
  const [selectedTaskType, setTaskType] = useState<string>("");
  const refStartModel = useRef<TextFieldProps>(null);
  const refEndModel = useRef<TextFieldProps>(null);
  const refNumberOfIds = useRef<TextFieldProps>(null);

  useEffect(() => {
    (async () => fetchData())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { subcategories } = await assetToolingService.getSubcategories();
      setSubcategories(subcategories.sort());

      const fields = await assetToolingService.getUsedCustomFields();

      const comOptions = fields?.complexity.type_config.options || [];
      setComplexityOptions(comOptions);

      const tasOptions = fields?.taskType.type_config.options || [];
      setTaskTypeOptions(tasOptions);

      const subOptions = fields?.subcategory.type_config.options || [];
      setSubcategoryOptions(subOptions);

      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to get available subcategories.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const generateIdsButtonClickHandler = async () => {
    if (
      !refStartModel ||
      !refStartModel.current ||
      !refEndModel ||
      !refEndModel.current ||
      !refNumberOfIds ||
      !refNumberOfIds.current
    ) {
      return;
    }

    try {
      setLoading(true);
      const customFieldSubcategory = subcategoryOptions.find((subcat) => subcat.name === selectedSubcategory);
      const startModel = parseInt(refStartModel.current.value as string, 10);
      const endModel = parseInt(refEndModel.current.value as string, 10);
      const numberOfIds = parseInt(refNumberOfIds.current.value as string, 10);
      await assetToolingService.generateIds(
        selectedSubcategory,
        startModel,
        endModel,
        numberOfIds,
        selectedComplexity,
        selectedTaskType,
        customFieldSubcategory?.id || "",
      );
      setLoading(false);
      navigate(routes.artAssetsIdsBrowser);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to create art asset IDs.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const reloadSubcategoriesButtonClickHandler = async () => {
    try {
      setLoading(true);
      const { subcategories } = await assetToolingService.reloadSubcategories();
      setSubcategories(subcategories.sort());
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to reload subcategories.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", p: 2 }}>
          <h1>Art Asset IDs Generator</h1>

          <div>
            <ul>
              <li>Select subcategory from the available list.</li>
              <li>Start and End model fields defines the range (inclusive) of the models to create variants for.</li>
              <li>
                Number of variants fields defines how many variants to create for each model. E.g.: Subcategory crop with
                model range 1-3 with 2 variants will create 6 assets (3x2=6):
                <ul>
                  <li>crop_001_001</li>
                  <li>crop_001_002</li>
                  <li>crop_002_001</li>
                  <li>crop_002_002</li>
                  <li>crop_003_001</li>
                  <li>crop_003_002</li>
                </ul>
              </li>
              <li>Complexity and Task Type fields are from ClickUp and they are used to define the task template to use.</li>
              <li>
                After generating the IDs edit the BundleID and FriendlyName in the{" "}
                <a
                  href="https://docs.google.com/spreadsheets/d/1lTvSrd31W25hen6MTk_OoCqQrCz4JfzYGTP8UkmVoXM/edit?gid=389466922"
                  target="_blank"
                  rel="noreferrer"
                >
                  sheets
                </a>{" "}
                before sending to ClickUp.
              </li>
            </ul>
          </div>

          <Divider></Divider>

          <div
            style={{
              marginTop: 10,
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Stack spacing={2}>
              <Stack direction="row" spacing={2}>
                <FormControl sx={{ mt: 1, width: "220px" }}>
                  <InputLabel id="subcategory-selector-label">Subcategory</InputLabel>
                  <Select
                    labelId="subcategory-selector-label"
                    id="entity-selector"
                    value={selectedSubcategory}
                    onChange={(e) => setSelectedSubcategory(e.target.value)}
                    label="Entity"
                  >
                    {subcategories.map((subcat) => (
                      <MenuItem key={subcat} value={subcat}>
                        {subcat}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button startIcon={<RefreshIcon />} variant="outlined" onClick={reloadSubcategoriesButtonClickHandler}>
                  Reload Subcategories
                </Button>
              </Stack>

              <Stack direction="row" spacing={2}>
                <FormControl sx={{ ml: 2, width: "140px" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    variant="standard"
                    inputRef={refStartModel}
                    id="input-start-model"
                    label="Start Model"
                    type="number"
                  />
                </FormControl>

                <FormControl sx={{ ml: 2, width: "140px" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    variant="standard"
                    inputRef={refEndModel}
                    id="input-end-model"
                    label="End Model"
                    type="number"
                  />
                </FormControl>

                <FormControl sx={{ ml: 2, width: "160px" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    variant="standard"
                    inputRef={refNumberOfIds}
                    id="input-number-of-ids"
                    label="Number of Variants"
                    type="number"
                  />
                </FormControl>
              </Stack>

              <Stack direction="row" spacing={2}>
                <FormControl sx={{ mt: 1, ml: 2, width: "220px" }}>
                  <InputLabel id="complexity-selector-label">Complexity</InputLabel>
                  <Select
                    labelId="complexity-selector-label"
                    id="complexity-selector"
                    value={selectedComplexity}
                    onChange={(e) => setSelectedComplexity(e.target.value)}
                    label="Complexity"
                  >
                    {complexityOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl sx={{ mt: 1, ml: 2, width: "220px" }}>
                  <InputLabel id="task-type-selector-label">Task Type</InputLabel>
                  <Select
                    labelId="task-type-selector-label"
                    id="task-type-selector"
                    value={selectedTaskType}
                    onChange={(e) => setTaskType(e.target.value)}
                    label="Task Type"
                  >
                    {taskTypeOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name || option.label || "[Couldn't identify label]"}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              <Stack sx={{ mt: 3 }}>
                <Button variant="outlined" onClick={generateIdsButtonClickHandler} sx={{ width: 120 }}>
                  Create IDs
                </Button>
              </Stack>
            </Stack>
          </div>
        </Paper>
      </Box>
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
      {loading && <LoadingOverlay />}
    </>
  );
};

export default ArtAssetsIdsGenerator;
