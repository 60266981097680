import * as React from "react";
import { useMemo, useState } from "react";
import { DateTime } from "luxon";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { Button, Card, CardActions, CardContent } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { EnvironmentState, getEnvironmentStageData, IEnvironment } from "../../../services/registry/EnvironmentService";
import { EnvironmentStateIndicator } from "./EnvironmentStateIndicator";
import { DeploymentLogSelector } from "../../../components/DeploymentLogSelector";

const EnvironmentCardHeader = ({ stage }: { stage: number }) => {
  const [backgroundColor, labelColor, label] = useMemo(() => {
    return getEnvironmentStageData(stage);
  }, [stage]);
  return (
    <div style={{ height: "70px", paddingRight: "15px", backgroundColor, overflow: "hidden" }}>
      <Typography gutterBottom variant="h1" component="div" color={labelColor} textAlign="right">
        <b>{label}</b>
      </Typography>
    </div>
  );
};

const EnvironmentCardTitle = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  height: 44px;
`;

const EnvironmentCardLine = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 32px;
`;

export interface EnvironmentCardProps {
  content: IEnvironment;
  onEditButtonClick: () => void;
  onTerminateButtonClick: () => void;
  onReviveButtonClick: () => void;
  onSetDefaultButtonClick: () => void;
  onDeployBackendImageButtonClick: () => void;
  onChangeActivePackageButtonClick: () => void;
  onChangeActiveSchemaBranchButtonClick: () => void;
  onChangeActiveSchemaVersionButtonClick: () => void;
  onShowGameServersButtonClick: () => void;
  onChangeServerImageBranchButtonClick: () => void;
  onChangeServerImageIdButtonClick: () => void;
  onShowDeploymentLogButtonClick: (logName: string) => void;
  onShowAllLogsButtonClick: () => void;
  onViewActivePackageButtonClick: () => void;
  onViewActiveSchemaBranchButtonClick: () => void;
  onViewActiveSchemaVersionButtonClick: () => void;
  onViewServerImageBranchButtonClick: () => void;
  onViewServerImageIdButtonClick: () => void;
}

export const EnvironmentCard = ({
  content,
  onEditButtonClick,
  onTerminateButtonClick,
  onReviveButtonClick,
  onSetDefaultButtonClick,
  onDeployBackendImageButtonClick,
  onChangeActivePackageButtonClick,
  onChangeActiveSchemaBranchButtonClick,
  onChangeActiveSchemaVersionButtonClick,
  onShowGameServersButtonClick,
  onChangeServerImageBranchButtonClick,
  onChangeServerImageIdButtonClick,
  onShowDeploymentLogButtonClick,
  onShowAllLogsButtonClick,
  onViewActivePackageButtonClick,
  onViewActiveSchemaBranchButtonClick,
  onViewActiveSchemaVersionButtonClick,
  onViewServerImageBranchButtonClick,
  onViewServerImageIdButtonClick,
}: EnvironmentCardProps) => {
  const [selectedDeploymentLog, setSelectedDeploymentLog] = useState<string | undefined>(undefined);
  const creationDate = DateTime.fromSeconds(content.creationDate).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS);
  const terminationDate = content.terminationDate
    ? DateTime.fromSeconds(content.terminationDate).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)
    : "-";
  const upTime = (content.terminationDate ?? Date.now() / 1000) - content.creationDate; // in seconds
  const estimatedCost = (upTime / 3600) * 0.0168; // TODO: assuming t4g.small here, get that information from our backend for more accuracy
  const cannotDeploy =
    content.terminationDate !== undefined ||
    (content.state !== EnvironmentState.HEALTHY &&
      content.state !== EnvironmentState.RUNNING &&
      content.state !== EnvironmentState.UNKNOWN &&
      content.state !== EnvironmentState.UNHEALTHY);
  const cannotEditOrTerminate =
    content.terminationDate !== undefined ||
    content.state === EnvironmentState.REFRESHING ||
    content.state === EnvironmentState.TERMINATED ||
    content.state === EnvironmentState.PENDING_TERMINATION;
  const cannotRevive = content.terminationDate === undefined || content.state !== EnvironmentState.TERMINATED;
  let activeSchemaVersion = "NOT SET";
  if (content.activeSchemaVersion !== undefined) {
    if (content.activeSchemaVersion > 0) {
      activeSchemaVersion = content.activeSchemaVersion.toString();
    } else {
      activeSchemaVersion = "latest";
    }
  }
  return (
    <Card sx={{ maxWidth: 640 }}>
      <EnvironmentCardHeader stage={content.stage} />
      <CardContent>
        <EnvironmentCardTitle>
          <Typography gutterBottom variant="h5" component="div">
            <b>{content.name + (content.isDefault ? " (default)" : "")}</b>
          </Typography>
          <EnvironmentStateIndicator state={content.state || EnvironmentState.UNKNOWN} style={{ marginTop: 3 }} />
        </EnvironmentCardTitle>
        <div
          style={{ display: "flex", flex: 1, flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
        >
          {content.previous !== undefined && <Button size="small">Show previous</Button>}
          <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
            <Typography variant="body2" color="text.secondary" textAlign="right" style={{ marginBottom: 2 }}>
              {creationDate}
            </Typography>
            <Typography variant="body2" color="text.secondary" textAlign="right" style={{ marginBottom: 2 }}>
              {terminationDate}
            </Typography>
          </div>
        </div>
        <Typography variant="body2" color="text.secondary" textAlign="right" style={{ marginBottom: 8 }}>
          Estimated cost: <b>${estimatedCost.toFixed(2)}</b> (USD)
        </Typography>
        <EnvironmentCardLine>
          <Typography variant="body1" color="text.secondary">
            Base URL: <b>{content.baseURL}</b>
          </Typography>
        </EnvironmentCardLine>
        <EnvironmentCardLine>
          <Typography variant="body1" color="text.secondary">
            Backend Image ID: <b>{content.backendImageId ?? "NOT SET"}</b>
          </Typography>
          <Button size="small" disabled={cannotDeploy} onClick={onDeployBackendImageButtonClick}>
            Deploy
          </Button>
        </EnvironmentCardLine>
        <EnvironmentCardLine>
          <Typography variant="body1" color="text.secondary">
            Active Package ID: <b>{content.activePackageId ?? "NOT SET"}</b>
          </Typography>
          <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "flex-end" }}>
            <Button
              size="small"
              disabled={cannotDeploy}
              startIcon={<OpenInNewIcon />}
              onClick={onViewActivePackageButtonClick}
            >
              View
            </Button>
            <Button
              size="small"
              disabled={cannotDeploy}
              onClick={onChangeActivePackageButtonClick}
              style={{ marginLeft: 8 }}
            >
              Change
            </Button>
          </div>
        </EnvironmentCardLine>
        <EnvironmentCardLine>
          <Typography variant="body1" color="text.secondary">
            Active Schema Branch: <b>{content.activeSchemaBranch ?? "NOT SET"}</b>
          </Typography>
          <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "flex-end" }}>
            <Button
              size="small"
              disabled={cannotDeploy}
              startIcon={<OpenInNewIcon />}
              onClick={onViewActiveSchemaBranchButtonClick}
            >
              View
            </Button>
            <Button
              size="small"
              disabled={cannotDeploy}
              onClick={onChangeActiveSchemaBranchButtonClick}
              style={{ marginLeft: 8 }}
            >
              Change
            </Button>
          </div>
        </EnvironmentCardLine>
        <EnvironmentCardLine>
          <Typography variant="body1" color="text.secondary">
            Active Schema Version: <b>{activeSchemaVersion}</b>
          </Typography>
          <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "flex-end" }}>
            <Button
              size="small"
              disabled={cannotDeploy}
              startIcon={<OpenInNewIcon />}
              onClick={onViewActiveSchemaVersionButtonClick}
            >
              View
            </Button>
            <Button
              size="small"
              disabled={cannotDeploy}
              onClick={onChangeActiveSchemaVersionButtonClick}
              style={{ marginLeft: 8 }}
            >
              Change
            </Button>
          </div>
        </EnvironmentCardLine>
        <EnvironmentCardLine>
          <Typography variant="body1" color="text.secondary">
            Server Image Branch: <b>{content.serverImageBranch || "default"}</b>
          </Typography>
          <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "flex-end" }}>
            <Button
              size="small"
              disabled={cannotDeploy}
              startIcon={<OpenInNewIcon />}
              onClick={onViewServerImageBranchButtonClick}
            >
              View
            </Button>
            <Button
              size="small"
              disabled={cannotDeploy}
              onClick={onChangeServerImageBranchButtonClick}
              style={{ marginLeft: 8 }}
            >
              Change
            </Button>
          </div>
        </EnvironmentCardLine>
        <EnvironmentCardLine>
          <Typography variant="body1" color="text.secondary">
            Server Image Id: <b>{content.serverImageId || "default"}</b>
          </Typography>
          <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "flex-end" }}>
            <Button
              size="small"
              disabled={cannotDeploy}
              startIcon={<OpenInNewIcon />}
              onClick={onViewServerImageIdButtonClick}
            >
              View
            </Button>
            <Button
              size="small"
              disabled={cannotDeploy}
              onClick={onChangeServerImageIdButtonClick}
              style={{ marginLeft: 8 }}
            >
              Change
            </Button>
          </div>
        </EnvironmentCardLine>
        {content.publicIpAddress !== undefined ? (
          <EnvironmentCardLine>
            <Typography variant="body1" color="text.secondary">
              Public IP Address: <b>{content.publicIpAddress}</b>
            </Typography>
          </EnvironmentCardLine>
        ) : null}
        {content.privateIpAddress !== undefined ? (
          <EnvironmentCardLine>
            <Typography variant="body1" color="text.secondary">
              Private IP Address: <b>{content.privateIpAddress}</b>
            </Typography>
          </EnvironmentCardLine>
        ) : null}
        <EnvironmentCardLine>
          <Typography variant="body1" color="text.secondary">
            Game-servers: <b>{content.gameServerCount ?? "0"}</b>
          </Typography>
          <Button size="small" onClick={onShowGameServersButtonClick}>
            Show
          </Button>
        </EnvironmentCardLine>
        <EnvironmentCardLine style={{ marginTop: 16 }}>
          <DeploymentLogSelector
            id="12"
            deploymentLogs={content.deploymentLogs || []}
            selectedDeploymentLog={selectedDeploymentLog}
            onDeploymentLogSelected={setSelectedDeploymentLog}
          />
          <Button
            size="small"
            onClick={() => onShowDeploymentLogButtonClick(selectedDeploymentLog || "")}
            disabled={!selectedDeploymentLog}
          >
            Show
          </Button>
        </EnvironmentCardLine>
        <EnvironmentCardLine style={{ marginTop: 8 }}>
          <div style={{ display: "flex", flexGrow: 1 }} />
          <Button size="small" onClick={onShowAllLogsButtonClick} style={{ marginTop: 4 }}>
            Show all logs
          </Button>
        </EnvironmentCardLine>
      </CardContent>
      <CardActions>
        <Button variant="outlined" startIcon={<EditIcon />} onClick={onEditButtonClick} disabled={cannotEditOrTerminate}>
          Edit
        </Button>
        {(content.terminationDate !== undefined && content.stage <= 7 && (
          <Button variant="outlined" startIcon={<AddIcon />} onClick={onReviveButtonClick} disabled={cannotRevive}>
            Revive
          </Button>
        )) || (
          <Button
            variant="outlined"
            startIcon={<DeleteIcon />}
            onClick={onTerminateButtonClick}
            disabled={cannotEditOrTerminate}
          >
            Terminate
          </Button>
        )}
        <div style={{ flexGrow: 1 }} />
        <Button onClick={onSetDefaultButtonClick} disabled={content.isDefault || cannotEditOrTerminate}>
          Set as Default
        </Button>
      </CardActions>
    </Card>
  );
};
